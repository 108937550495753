<template>
  <div class="home">
    <div class="mainBox">
      <div class="box">
          <div class="login-img">
          </div>
          <div class="login-form">
              <p class="login-name">管理系统登录</p>
              <el-form ref="user" :model="user" :rules="rules" >
                <el-form-item prop="username">
                  <el-input prefix-icon="el-icon-s-custom" v-model="user.username" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item prop="password" style="margin-top: 32px">
                  <el-input prefix-icon="el-icon-s-goods" v-model="user.password" show-password placeholder="请输入密码"></el-input>
                </el-form-item>
              </el-form>
          </div>
          <el-button @click="LoginSubmit('user')" type="primary" class="submitBtn">登录</el-button>
      </div>
      <img class="imgSrc" src="../assets/106app.png" alt="" srcset="">
    </div>
  </div>
</template>
<script>
import {LoginRequest} from '../RequestPort/Login/LoginRequest'
  import md5 from 'js-md5'
  import axios from "axios";
  export default {
    name: 'home',
    data(){
      return {
        baseURL:axios.defaults.baseURL,
        user:{
          username:'',
          password:'',
          authcode:''
        },
        rules:{
          username:[{ required: true, message: '请输入用户名', trigger: 'blur' }],
          password:[{ required: true, message: '请输入密码', trigger: 'blur' }],
          authcode:[{ required: true, message: '请输入验证码', trigger: 'blur' }],
        }
      }
    },
    methods: {
      loadimg(){
        document.getElementById("kaptcha").src=this.baseURL+'/kaptcha/render?d='+new Date();
      },
      CalcuMD5(pwd) {
        let onepwd = md5(pwd);
        let newpwd = onepwd.substring(onepwd.length-8)
        let twopwd = md5(newpwd)
        let endpwd = twopwd.substring(twopwd.length-8)
        return endpwd;
      },
      LoginSubmit(user){
        this.$refs[user].validate((valid) => {
          if(!valid) return false
          LoginRequest({
            username: this.user.username,
            password: this.CalcuMD5(this.user.password)
          }).then((e) => {
            let tokenInfo = e.data.tokenInfo
            localStorage.setItem('MenuTree',JSON.stringify(e.data.permissionList))
            localStorage.setItem('SelectMenuArray',JSON.stringify(e.data.permissionList[0].children))
            localStorage.setItem('tokenName', tokenInfo.tokenName)
            localStorage.setItem('tokenValue', tokenInfo.tokenValue)
            localStorage.setItem('userName', e.data.username)
            localStorage.setItem('userId', e.data.userId)
            localStorage.setItem('SelectMenuArray', JSON.stringify(e.data.permissionList[0].children))
            //连接socket
            this.$store.commit('changeSocketState', true)
            this.$message({
              message: '恭喜你，登录成功',
              duration: 1500,
              type: 'success'
            });

            setTimeout(() => {
              this.$router.push('/home')
            }, 1000)


          }).catch((e)=>{
            this.user.authcode=''
            this.loadimg()
          })
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .home{
    font-family: sans-serif;
    background:url('./../assets/login-bgc.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
  }
  .mainBox{
    position: absolute;
    top: 190px;
    right: 306px;
  }
  .login-form{width: 300px;}
  .login-name{color:#fff;font-size: 36px;text-align: center;margin-bottom: 40px;}
  .box
  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width:400px;
    height: 480px;
    padding: 0 58px 40px;
    box-sizing:border-box;
    border-radius: 10px;
    ::v-deep .el-input__inner{
      height: 60px;
      background-color: transparent !important;
    }
    ::v-deep.el-input__icon{
      line-height: 60px;
    }
    ::v-deep.el-icon-s-custom:before {
      color: #267FFF;
    }
    ::v-deep.el-icon-s-goods:before {
      color: #267FFF;
    }
  }
  .box h2
  {
    margin-bottom: 30px;
    padding:0;
    color:#1A3B6A;
    text-align:center;
    font-size: 34px;
    letter-spacing:10px
  }

  .submitBtn{
    width: 320px;
    height: 60px;
    margin-top: 10px;
    background: #4B5FD8;
    border-radius: 30px;
    border: 0;
  }
  ::v-deep button span {
    font-size: 24px;
  }
</style>
